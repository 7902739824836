import styled from 'styled-components';
import FooterImage from 'common/assets/image/hosting/footer-bg.png';

const FooterWrapper = styled.footer`
  position: relative;
  overflow: hidden;
  a {
    color: #E7E7E7;
    font-size: 13px;
    line-height: 16px;
    transition: all 0.2s ease;
    font-weight: 300;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: #ADADC2;
    }
  }
  @media (min-width: 576px) {
   /* padding-top: 270px;
    margin-top: -150px;*/
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      padding-bottom: 100%;
      border-top-right-radius: 11%;
      top: 14%;
      left: 0;
      pointer-events: none;
      background-color: rgba(18, 103, 230, 0.03);
      transform: rotate(-6deg);
      @media (max-width: 767px) {
        padding-bottom: 1%;
      }
    }
  }

  .footer_container {
    background-repeat: no-repeat;
    background-position: center 50px;
    padding-top: 50px;
    padding-bottom: 50px; margin-top:15px;
    
    position: relative;
    @media (min-width: 576px) {
      background-image: url(${FooterImage});
    }
    @media (max-width: 990px) {
      padding-bottom: 20px;
      text-align:center;
    }
  }
`;

const List = styled.ul`
{float:right; height:auto; margin-top:15px;}
li{ float:left; margin-left:10px; }
`;

const ListItem = styled.li`{ 
   @media (max-width: 576px) {
    width:46%;
    }
    }
  a {
    color: #E7E7E7;
    font-size: 13px;
    line-height: 16px;
    transition: all 0.2s ease;
    font-weight: 300;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: #ADADC2;
    }
  }
`;

export { List, ListItem };

export default FooterWrapper;
