import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { x } from 'react-icons-kit/feather/x';
import Logo from 'common/components/UIElements/Logo';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import NavbarWrapper, { MenuArea, MobileMenu } from './navbar.style';
import LogoImage from 'common/assets/image/cryptoModern/nadi_logo.png';
import LogoImageAlt from 'common/assets/image/cryptoModern/logo.png';

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query {
      cryptoModernJson {
        navbar {
          logo {
            publicURL
          }
          navMenu {
            id
            label
            path
            offset
          }
        }
        navbarSecond {
          logo {
            publicURL
          }
          navMenu {
            id
            label
            path
            offset
          }
        }
      }
    }
  `);

  const [isClient, setIsClient] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const [mobileMenu, setMobileMenu] = useState(false);
  
  useEffect(() => {
    setIsClient(true);
    setCurrentPath(window.location.pathname);
  }, []);

  const isHomePage = isClient && (
    currentPath === '/' || 
    currentPath === '' || 
    currentPath === '/index.html'
  );

  const navMenu = isHomePage
    ? data?.cryptoModernJson?.navbar?.navMenu || []
    : data?.cryptoModernJson?.navbarSecond?.navMenu || [];

  const scrollItems = navMenu.map((item) => {
    return item.path.replace(/[/#]+/g, '');
  });

  const handleRemoveMenu = () => {
    setMobileMenu(false);
  };

  const handleMenuClick = (e, path) => {
    handleRemoveMenu();
    
    if (!isHomePage && path.includes('#')) {
      e.preventDefault();
      window.location.href = path;
    }
  };

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <Logo href="/#" logoSrc={LogoImage} title="NadiAI" className="main-logo" />
        <Logo href="/#" logoSrc={LogoImageAlt} title="NadiAI" className="logo-alt" />
        <MenuArea>
          {isHomePage ? (
            <ScrollSpyMenu className="menu" menuItems={navMenu} offset={-84} />
          ) : (
            <nav className="nav desktop-only">
              <ul className="menu">
                {navMenu.map((menu, index) => (
                  <li key={`menu_key${index}`}>
                    <a href={menu.path}>{menu.label}</a>
                  </li>
                ))}
              </ul>
            </nav>
          )}
          
          <a href="/joinwaitlist">
            <Button className="trail" title="Join wait list" />
          </a>
          <a href="https://app.nadiai.ai" target="_blank" rel="noopener noreferrer">
            <Button className="trail login" title="Login" />
          </a>

          <Button
            className="menubar menu"
            icon={mobileMenu ? <Icon className="bar" icon={x} /> : <Icon className="close" icon={menu} />}
            color="#0F2137"
            variant="textButton"
            onClick={() => setMobileMenu(!mobileMenu)}
          />
        </MenuArea>
      </Container>

      <MobileMenu className={`mobile-menu ${mobileMenu ? 'active' : ''}`}>
        <Container>
          {isClient ? (
            isHomePage ? (
              <Scrollspy
                className="menu in home page"
                items={scrollItems}
                offset={-84}
                currentClassName="active"
              >
                {navMenu.map((menu, index) => (
                  <li key={`menu_key${index}`}>
                    <AnchorLink 
                      href={menu.path} 
                      offset={menu.offset} 
                      onClick={handleRemoveMenu}
                    >
                      {menu.label}
                    </AnchorLink>
                  </li>
                ))}
              </Scrollspy>
            ) : (
              <ul className="menu in else page">
                {navMenu.map((menu, index) => (
                  <li key={`menu_key${index}`}>
                    <a 
                      href={menu.path}
                      onClick={(e) => handleMenuClick(e, menu.path)}
                    >
                      {menu.label}
                    </a>
                  </li>
                ))}
              </ul>
            )
          ) : (
            <ul className="menu">
              {navMenu.map((menu, index) => (
                <li key={`menu_key${index}`}>
                  <a href={menu.path}>{menu.label}</a>
                </li>
              ))}
            </ul>
          )}
          <a href="/joinwaitlist">
            <Button title="Join wait list" />
          </a>
          <a href="https://app.nadiai.ai" target="_blank" rel="noopener noreferrer">
            <Button className="trail login" title="Login" />
          </a>
        </Container>
      </MobileMenu>
    </NavbarWrapper>
  );
};

export default Navbar;